@font-face {
    font-family: 'Colfax Bold';
    src: url('./fonts/Colfax-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

body {
    font-family: 'Colfax Bold', sans-serif;
    overflow: hidden;
    height: 100%;
}

html, #root {
  height: 100%;
  color: #000000;
}

.custom-radio input[type="radio"] {
  display: none;
}

.custom-radio input[type="radio"] + span {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid #ccc;
  margin-right: 1rem;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}

.custom-radio input[type="radio"]:checked + span {
  background-color: #fff;
  border-color: #ef4444; /* Tailwind red-600 */
  font-family: 'Arial'
}

.custom-radio input[type="radio"]:checked + span::before {
  content: '✓';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ef4444; /* Tailwind red-600 */
  font-size: 1rem;
  line-height: 1rem;
}



